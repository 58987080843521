.station-line.mbta {
  color: #0f172a;
  font-family: "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  border-radius: 1em;
  padding: 0.1em 0.5em;
  display: block;
  float: left;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
}

.station-lines-small.mbta .station-line.mbta {
  font-size: 0.9em;
}
